.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border: none;
  background: #131313;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border: 1px solid #434343;
  border-radius: 1px;
  background: #131313;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #434343;
}

.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background:  #434343;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  background-color: #434343;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.react-tel-input .country-list .country .dial-code {
  color: #ffffff;
}

.react-tel-input .country-list .country:hover {
  background: rgb(1, 50, 80);
}

.react-tel-input .country-list .country.highlight {
  background-color: #8d8b8b;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #131313;
  border: 1px solid #434343;
  border-radius: 1px;
  line-height: 25px;
  height: 35px;
  width: 100%;
  outline: none;
}

.ant-input-prefix {
  margin-right: 16px !important
}

::webkit-scrollbar-thumb {
  background-color: #223c50;
}