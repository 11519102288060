html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;

  overflow: auto;
}


.wrapper {
  padding: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
}

.example-wrapper {
  display: flex;
  height: 100%;
  margin-top: 20px;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: url('https://vixprofit.com/static/x-profit-white-logo.png');
  background-size: 91px;
  background-repeat: no-repeat;

}

.logo {
  float: left;
  width: 120px;
  height: 61px;
  margin: 10px 3px 0px 0;
  background: url('https://vixprofit.com/static/x-profit-white-logo.png');
  background-size: 91px;
  background-repeat: no-repeat;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-col {
  padding-left: 3px;
  padding-right: 3px;
}

.site-card-border-less-wrapper {
  background-color: black;
}

.ant-card-body {
  padding: 10px !important
}

.filter_row {
  margin: 10px 0px 10px;
  padding: 10px 10px 10px;
  background-color: #1f1f1f;
}

.ant-layout-header {
  height: 55px !important;
  line-height: 55px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}


.legend {
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 1;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
}

.bar-legend {
  width: 10px;
  height: 55px;
}

.ant-badge-status-text {
  margin-right: 19px !important;
}

.ant-badge-status-dot {
  margin-right: -6px !important;
  width: 10px !important;
  height: 10px !important;
}


.inner-col {
  padding: 2px;
  height: 102%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}


.scrollable-container {
  height: 100px;
  overflow-y: scroll;
}

.background {
  padding-top: 60px;
  height: 300px;
  background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
}

.card-tariffs:hover {
  border: 1px solid #59c7fb;
  background-color: #1c5196 !important;
  cursor: pointer
}

.card-pre-levels {
  padding: 0 !important;
  background-color: #323437 !important;
  border: 1px solid #d0d9dd !important;
  padding: 0 !important;
}

.card-pre-text {

  padding: 0 !important;
}



.levels-pre-select {
  border-radius: 3px 0 0 3px !important
}

.levels-custom-select {
  background-color: "red" !important;
  border-top-left-radius: 10px !important;
}

.levels-card {
  padding: 0 !important;
}


.title-extra:hover {
  border: 1px solid #59c7fb;
  background-color: #1c5196 !important;
  cursor: pointer
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border: none;
  background: #131313;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border: 1px solid #434343;
  border-radius: 1px;
  background: #131313;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #434343;
}

.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background:  #434343;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  background-color: #434343;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.react-tel-input .country-list .country .dial-code {
  color: #ffffff;
}

.react-tel-input .country-list .country:hover {
  background: rgb(1, 50, 80);
}

.react-tel-input .country-list .country.highlight {
  background-color: #8d8b8b;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #131313;
  border: 1px solid #434343;
  border-radius: 1px;
  line-height: 25px;
  height: 35px;
  width: 100%;
  outline: none;
}

.ant-input-prefix {
  margin-right: 16px !important
}

::webkit-scrollbar-thumb {
  background-color: #223c50;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.user-button {
    white-space: nowrap;
    width: 100%;
    border: none;
    background: none;
    color: gray;
    padding-left: 0;
    padding-right: 0;

}

.user-button:hover{
    background: rgb(1, 50, 80);
}
