html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;

  overflow: auto;
}


.wrapper {
  padding: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
}

.example-wrapper {
  display: flex;
  height: 100%;
  margin-top: 20px;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: url('https://vixprofit.com/static/x-profit-white-logo.png');
  background-size: 91px;
  background-repeat: no-repeat;

}

.logo {
  float: left;
  width: 120px;
  height: 61px;
  margin: 10px 3px 0px 0;
  background: url('https://vixprofit.com/static/x-profit-white-logo.png');
  background-size: 91px;
  background-repeat: no-repeat;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-col {
  padding-left: 3px;
  padding-right: 3px;
}

.site-card-border-less-wrapper {
  background-color: black;
}

.ant-card-body {
  padding: 10px !important
}

.filter_row {
  margin: 10px 0px 10px;
  padding: 10px 10px 10px;
  background-color: #1f1f1f;
}

.ant-layout-header {
  height: 55px !important;
  line-height: 55px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}


.legend {
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 1;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
}

.bar-legend {
  width: 10px;
  height: 55px;
}

.ant-badge-status-text {
  margin-right: 19px !important;
}

.ant-badge-status-dot {
  margin-right: -6px !important;
  width: 10px !important;
  height: 10px !important;
}


.inner-col {
  padding: 2px;
  height: 102%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}


.scrollable-container {
  height: 100px;
  overflow-y: scroll;
}

.background {
  padding-top: 60px;
  height: 300px;
  background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
}

.card-tariffs:hover {
  border: 1px solid #59c7fb;
  background-color: #1c5196 !important;
  cursor: pointer
}

.card-pre-levels {
  padding: 0 !important;
  background-color: #323437 !important;
  border: 1px solid #d0d9dd !important;
  padding: 0 !important;
}

.card-pre-text {

  padding: 0 !important;
}



.levels-pre-select {
  border-radius: 3px 0 0 3px !important
}

.levels-custom-select {
  background-color: "red" !important;
  border-top-left-radius: 10px !important;
}

.levels-card {
  padding: 0 !important;
}


.title-extra:hover {
  border: 1px solid #59c7fb;
  background-color: #1c5196 !important;
  cursor: pointer
}