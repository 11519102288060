.user-button {
    white-space: nowrap;
    width: 100%;
    border: none;
    background: none;
    color: gray;
    padding-left: 0;
    padding-right: 0;

}

.user-button:hover{
    background: rgb(1, 50, 80);
}